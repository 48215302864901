<template>
    <Modal :footer-actions="modalActions" slot-class="p-0 overflow-auto" ref="modal">
        <template #title>
            <div>{{ modalTitle }}</div>
        </template>

        <div class="tabs">
            <li :class="{ active: isEimzoTab }" @click="toggleTabs(0)">
                <i class="fas fa-key"></i>
                <span>{{ $l("platon.ERI", "ERI") }}</span>
            </li>
            <li :class="{ active: !isEimzoTab }" @click="toggleTabs(1)">
                <img src="../../components/eimzo/eimzo.png" alt="eimzo logo" width="18px" height="18px" />
                <span>{{ $l("platon.qrcode", "QR kod") }}</span>
            </li>
        </div>

        <div class="tab-container">
            <div class="tab-content" :class="{ active: isEimzoTab, offscreen: isEimzoTab }">
                <div v-if="error" class="p-3">
                    <div class="alert alert-danger mb-0">
                        {{ eimzoNotFoundText }}
                    </div>
                </div>
                <div v-else-if="certs.length === 0" class="p-3">
                    <div class="alert alert-danger mb-0">
                        {{ $l("platon.eimzo_certs_not_found", "Электрон калитлар топилмади") }}
                    </div>
                </div>
                <div v-else>
                    <EIMZOCert
                        v-for="(cert, index) in certs"
                        :key="cert.id"
                        @click.native="onSelected(cert)"
                        :class="{ selected: selectedKey === cert }"
                        @dblclick.native="
                            () => {
                                onSelected(cert)
                                $refs.modal.handleAction(modalActions[0])
                            }
                        "
                        :cert="cert"
                    />
                </div>
            </div>
            <div class="tab-content" :class="{ active: !isEimzoTab, 'offscreen-qr': isEimzoTab }">
                <EIMZOQRTab @close-modal="$emit('close')" :isEimzoTab="isEimzoTab" />
            </div>
        </div>
    </Modal>
</template>

<script>
/**
 * @typedef EIMZOSignOptions
 *
 * @property {string} title
 */

import Modal from "../extended/Modal.vue"
import EIMZOCert from "@Platon/components/eimzo/EIMZOCert.vue"
import EIMZOQRTab from "@Platon/components/eimzo/EIMZOQRTab.vue"
import { DateTime } from "luxon"
import { isMobile } from "@Platon/core/helpers"
import EimzoMixin from "../login/EimzoMixin"

export default {
    name: "EIMZOSign",
    mixins: [EimzoMixin],

    components: {
        EIMZOCert,
        Modal,
        EIMZOQRTab
    },

    props: {
        signText: {
            type: [String, Array],
            default: ""
        },

        onSign: {
            type: Function
        },

        options: {
            type: Object,
            default: () => ({})
        },

        timestampProvider: {
            type: Function
        }
    },

    data() {
        return {
            error: false,
            certs: [],
            selectedKey: null,
            savedLoadedKey: this.options.loadedKey,
            isEimzoTab: true,
            client: null
        }
    },

    created() {
        this.client = this.getClient()
        this.init()
    },

    methods: {
        toggleTabs(index) {
            if (index === 0) {
                this.isEimzoTab = true
            } else {
                this.isEimzoTab = false
            }
            console.log("toggle tabs", this.isEimzoTab)
        },
        async checkKeyStatus() {
            try {
                const certs = await this.client.listAllUserKeys()
                if (certs.length > 0) {
                    this.isEimzoTab = true
                } else {
                    this.isEimzoTab = false
                }
            } catch (e) {
                this.isEimzoTab = false
            }
        },
        async init() {
            if (this.isDirectClient) {
                await this.installProjectApiKey()
            }

            try {
                await this.client.install()

                this.onCertsLoaded(await this.getAvailableCerts())
            } catch (e) {
                this.isEimzoTab = false

                this.error = e
            }

            await this.checkKeyStatus()
        },
        async getAvailableCerts() {
            const { whiteList } = this.options
            let certs = await this.client.listAllUserKeys()
            if (!whiteList || !Array.isArray(whiteList)) return certs

            certs = certs.filter((cert) => {
                return whiteList.find((key) => cert.PINFL === key || cert.TIN === key)
            })

            return certs
        },

        onSelected(cert) {
            let isValid = DateTime.fromJSDate(cert.validTo).diffNow().as("seconds") > 0

            if (isValid) this.selectedKey = cert
        },

        /**
         * @param {Cert} a
         * @param {Cert} b
         */
        newKeysFirst(a, b) {
            let end1 = DateTime.fromJSDate(a.validTo)
            let end2 = DateTime.fromJSDate(b.validTo)

            return end2.ts - end1.ts
        },

        async handleSign() {
            if (!this.selectedKey) return
            try {
                const textsToSign = Array.isArray(this.signText) ? this.signText : [this.signText]

                for (let text of textsToSign) {
                    if (typeof text === "function") {
                        text = await text()
                    }

                    let signResult
                    let loadedKey

                    // try with saved loaded key
                    if (this.savedLoadedKey && this.savedLoadedKey.id) {
                        try {
                            signResult = await this.client.createPkcs7(
                                this.savedLoadedKey.id,
                                text,
                                this.timestampProvider,
                                this.options.hasChallenge
                            )
                            loadedKey = this.savedLoadedKey
                        } catch {
                            console.warn("Cannot sign with saved loaded key, ask for new")
                        }
                    }
                    if (!signResult) {
                        this.savedLoadedKey = loadedKey = await this.client.loadKey(this.selectedKey)
                        signResult = await this.client.createPkcs7(
                            loadedKey.id,
                            text,
                            this.timestampProvider,
                            this.options.hasChallenge
                        )
                    }

                    try {
                        this.onSign && this.onSign(signResult, { ...loadedKey })
                    } catch (e) {
                        console.warn("Error in onSign handler", e)
                    }
                }
            } catch (e) {
                console.warn(e)
                this.$platonApp.errorMessageBox("Калит пароли тўғри киритилганлиги текширинг")

                return true
            }
        },

        /**
         * @param {Cert[]} certs
         */
        onCertsLoaded(certs) {
            this.certs = certs.sort(this.newKeysFirst)

            if (this.options.certSerialNumber) {
                const lookingCert = this.options.certSerialNumber

                this.certs = this.certs.filter((c) => {
                    return c.serialNumber === lookingCert || c.PINFL == lookingCert || c.PINFL == lookingCert
                })

                if (this.certs.length > 0) {
                    this.selectedKey = this.certs[0]

                    if (this.options.autoSign === true) {
                        this.$refs.modal.handleAction(this.modalActions[0])
                    }
                }
            }
        }
    },
    computed: {
        modalTitle() {
            return this.options.title || this.$l("platon.sign_with_eimzo", "E-IMZO орқали имзолаш")
        },

        eimzoNotFoundText() {
            let mobile =
                "Сизда RS Imzo иловаси ўрнатилмаган. RS Imzo иловасини юклаб олиш учун қуйидаги ўрнатиш тугмасини босинг."
            let desktop =
                "E-IMZO билан боғланишда хатолик. Сизда E-IMZO модули ёки E-IMZO браузери ўрнатилмаганга ўхшайди"

            return isMobile()
                ? this.$l("platon.eimzo_not_found_mobile", mobile)
                : this.$l("platon.eimzo_not_found_desktop", desktop)
        },

        modalActions() {
            return [
                this.error
                    ? {
                          text: isMobile()
                              ? this.$l("platon.eimzo_site_mobile", "RS Imzo ўрнатиш")
                              : this.$l("platon.eimzo_site_desktop", "E-IMZO сайти"),
                          icon: "fa fa-external-link-alt",
                          handler() {
                              isMobile()
                                  ? window.open("https://play.google.com/store/apps/details?id=uz.realsoft.rsimzo")
                                  : window.open("https://e-imzo.uz/")
                          }
                      }
                    : {
                          text: this.options.signText || this.$l("platon.eimzo_sign", "Имзолаш"),
                          icon: "fas fa-key",
                          handler: this.handleSign,
                          disabled: this.selectedKey == null || !this.isEimzoTab
                      },
                { text: this.$l("platon.cancel", "Бекор қилиш") }
            ]
        }
    }
}
</script>
<style scoped lang="scss">
.tabs {
    display: flex;
    justify-content: center;
    user-select: none;
    border-bottom: 1px solid var(--pl-login-eimzo-tab-border-color);

    li {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 4px;
        border-bottom: 2px solid transparent;
        font-size: 15px;
        line-height: 22px;
        transition: all 0.3s;
        color: var(--pl-login-eimzo-tab-color);
        font-weight: bold;
        transition: color 0.5s ease, border-bottom 0.5s ease;

        &:hover {
            background: var(--pl-login-eimzo-tab-element-bg-hover);
        }

        &:active {
            background: var(--pl-login-eimzo-tab-element-bg-active);
        }

        span {
            padding: 4px;
        }

        &.active {
            border-bottom: 2px solid var(--pl-top-bar);
            color: var(--pl-top-bar);
        }
    }
}

.tab-container {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.tab-content {
    flex: 0 0 100%;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
}

.offscreen {
    transform: translateX(0%);
}

.offscreen-qr {
    transform: translateX(100%);
}
</style>
