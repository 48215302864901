<template>
    <div class="canvas-container">
        <canvas ref="qrCanvas" width="180" height="180"></canvas>
        <div v-if="arrayOf16">
            <div @click="copy" class="copy">
                <span v-for="(chunk, index) in arrayOf16" :key="index">{{ chunk }}</span>
            </div>
            <div v-if="timeLeft > 0" class="timer">
                <span>{{ formattedTimeLeft }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcode"
import { EIMZOMobile } from "./e-imzo-mobile.js"
import { isMobile } from "../../core/helpers"
import { API_BASE_URL } from "@Platon/const"
import LoginMixin from "@Platon/components/login/LoginMixin"
import ToastMixin from "@Platon/mixins/ToastMixin"

export default {
    mixins: [LoginMixin, ToastMixin],
    data() {
        return {
            arrayOf16: null,
            qrCanvas: null,
            timer: null,
            timeLeft: 80,
            timerId: null
        }
    },
    props: {
        isEimzoTab: Boolean
    },
    computed: {
        formattedTimeLeft() {
            const minutes = Math.floor(this.timeLeft / 60)
                .toString()
                .padStart(2, "0")
            const seconds = (this.timeLeft % 60).toString().padStart(2, "0")
            return `${minutes}:${seconds}`
        }
    },
    methods: {
        copy() {
            const text = this.arrayOf16.join("\n")
            navigator.clipboard.writeText(text)
        },
        startTimer() {
            this.timeLeft = 80
            if (this.timer) {
                clearInterval(this.timer)
            }
            this.timer = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft--
                } else {
                    clearInterval(this.timer)
                    this.signIn()
                }
            }, 1000)
        },

        async checkStatus(documentId, challange) {
            try {
                const { data } = await this.$http.get(`auth/e-imzo/mobile/status`, {
                    params: { documentId },
                    baseURL: API_BASE_URL
                })

                const status = data.status

                if (status === 1) {
                    // continue
                    await this.signInSuccess(documentId)
                    this.onCloseQrcodeModal()
                } else if (status === 2) {
                    // if (pending.value) {
                    //     timerId.value = setTimeout(() => {
                    //         this.checkStatus(documentId, challange)
                    //     }, 1000)
                    // } else {
                    //     onCloseQrcodeModal()
                    //     showSnackbar({ message: t("Time_has_expired"), type: "error" })
                    // }
                } else {
                    this.onCloseQrcodeModal()
                }

                return data.value
            } catch (e) {
                this.errorToast(`Status error: ${e.message}`)
                throw new Error("makeAuth error")
            }
        },

        async signInSuccess(documentId) {
            try {
                const { data } = await this.$http.get(`auth/eimzo-v2/${documentId}`, {
                    baseURL: API_BASE_URL
                })
                this.$emit("close-modal")
                this.onLoginSuccess({ user: data.user, token: data.token })
            } catch (e) {
                this.errorToast(`Eimzo-v2 error: ${e.message}`)
            }
        },

        async getChallenge() {
            const { data } = await this.$http.get("auth/e-imzo/mobile", {
                baseURL: API_BASE_URL
            })

            return data
        },

        async signIn() {
            const { challange, documentId, siteId } = await this.getChallenge()
            if (!challange) {
                return
            }

            this.startTimer()

            const eim = new EIMZOMobile(siteId)
            // console.log("eim", eim)

            const res = eim.makeQRCode(documentId, challange)
            // console.log("res", res)

            const hashedQrCode = res[0]
            const qr = res[1]
            this.arrayOf16 = hashedQrCode.match(/.{1,16}/g)

            if (isMobile()) {
                this.$emit("close-modal")
                window.open("eimzo://sign?qc=" + qr)
            } else {
                this.generateQRCode(qr)
            }

            this.timerId = setInterval(async () => {
                await this.checkStatus(documentId, challange)
            }, 2000)
        },
        generateQRCode(qr) {
            QRCode.toCanvas(this.$refs.qrCanvas, qr, function (error) {
                if (error) console.error("Error generating QR Code:", error)
            })
        },

        onCloseQrcodeModal() {
            clearInterval(this.timerId)
        }
    },
    mounted() {
        if (!this.isEimzoTab) {
            this.signIn()
        }
    },
    beforeDestroy() {
        this.onCloseQrcodeModal()
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    watch: {
        isEimzoTab(newValue) {
            if (!newValue) {
                this.signIn()
            } else {
                if (this.timer) {
                    clearInterval(this.timer)
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">
.canvas-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 8px;

    @media (max-width: 420px) {
        flex-direction: column;
        gap: 5px;
    }
}

.canvas-container div span {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 1px;
    font-family: monospace;
}

.canvas-container .timer {
    text-align: center;
    margin: 5px 0;
}

.canvas-container div.copy {
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
}

.canvas-container div.copy:hover {
    background-color: var(--pl-login-eimzo-tab-copy-bg-hover);
}
</style>
