<template>
    <div class="qr-container">
        <h3>{{ $l("platon.scan", "QR-кодни сканерланг") }}</h3>
        <p>
            {{ $l("platon.scan_qr", "Э-имзо иловаси орқали ҚР-кодни сканерланг!") }}
        </p>

        <qrSignIn @close-modal="closeModal" :isEimzoTab="isEimzoTab" />

        <div class="links">
            <a href="https://play.google.com/store/apps/details?id=uz.yt.idcard.eimzo" target="_blank" type="button">
                <i class="fab fa-google-play"></i>
                <span>{{ $l("platon.google_play", "Google Play") }}</span>
            </a>

            <a
                href="https://apps.apple.com/uz/app/e-imzo-id-%D0%BA%D0%B0%D1%80%D1%82%D0%B0/id1563416406"
                target="_blank"
                type="button"
            >
                <i class="fab fa-app-store-ios"></i>
                <span>{{ $l("platon.app_store", "App Store") }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import qrSignIn from "./qr-sign-in.vue"
export default {
    name: "EIMZOQRTab",
    props: {
        isEimzoTab: Boolean
    },
    components: {
        qrSignIn
    },
    methods: {
        closeModal() {
            this.$emit("close-modal")
        }
    }
}
</script>

<style scoped lang="scss">
.qr-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px 15px;
    min-height: 200px;

    h3 {
        font-size: 24px;
        font-weight: 500;
    }

    p {
        opacity: 0.8;
        margin-bottom: 0;
        text-align: center;
    }
}

.qr-container .links {
    display: flex;
    align-items: center;
    gap: 5px;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        padding: 8px 14px;
        border-radius: 5px;
        background-color: var(--pl-login-eimzo-link-bg);
        color: var(--pl-top-bar);
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        transition: all 0.3s;

        &:hover {
            background-color: var(--pl-login-eimzo-link-hover);
        }

        &:active {
            background-color: var(--pl-login-eimzo-link-active);
        }

        i {
            font-size: 22px;
        }
    }
}

@media (max-width: 375px) {
    .qr-container h3 {
        font-size: 20px;
    }

    .qr-container p {
        font-size: 14px;
    }

    .qr-container .links {
        flex-direction: column;
    }

    .qr-container .links a {
        width: 100%;
    }
}
</style>
